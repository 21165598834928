import type { JSX } from 'react';
import { RoundShapedPicturesWithDescription } from '@/components';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

export type RoundShapedPicturesWithDescriptionProps =
  SliceComponentProps<Content.RoundShapedPicturesWithDescriptionSlice>;

const RoundShapedPicturesWithDescriptionSlice = ({
  slice,
}: RoundShapedPicturesWithDescriptionProps): JSX.Element => {
  return <RoundShapedPicturesWithDescription {...slice} />;
};

export default RoundShapedPicturesWithDescriptionSlice;
